import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProgramService } from 'src/app/shared/services/program.service';
import { IndexComponent } from './index/index.component';
import { ToeFileUploadComponent } from './toe-file-upload/toe-file-upload.component';
import { ToeResponseComponent } from './toe-response/toe-response.component';

const routes: Routes = [
  {
    path: ':id/:vendorID',
    component: IndexComponent,data: { breadcrumb: (data: any) => `${data?.opportunity?.opportunity_number}` },
    resolve: { opportunity: ProgramService },
    children: [
      {
        path: 'toe-files',
        component: ToeFileUploadComponent, //data:{breadcrumb:"Terms of Engagement"},
        loadChildren: () => import('./toe-upload.module').then(m => m.ToeUploadModule)
      },
      {
        path: 'toe-response', //data:{breadcrumb:"Terms of Engagement"},
        component: ToeResponseComponent,
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ToeUploadRoutingModule { }
