import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ToeListService } from 'src/app/shared/services/toe-list.service';
import { pdfExporter } from 'quill-to-pdf';
import * as moment from 'moment';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
// import * as saveAs from 'file-saver';
import { saveAs } from 'file-saver';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { ViewEncapsulation } from '@angular/core';
declare let Quill: any;

@Component({
  selector: 'app-toe-file-upload',
  templateUrl: './toe-file-upload.component.html',
  styleUrls: ['./toe-file-upload.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  encapsulation:ViewEncapsulation.Emulated
})
export class ToeFileUploadComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  @ViewChild('stepper', { static: false }) stepper: MatStepper|any;
  
  quillInstance: any;
  showCreate = true;
  showQuill = false;
  toefileEngagement: any;
  fileDeliveryPartner: any;
  contractCreation: any;
  signedContract: any;
  toefileEngagement1: any[] = [];
  fileDeliveryPartner1: any[] = [];
  contractCreation1: any[] = [];
  signedContract1: any[] = [];
  data: any;
  customStyle = {
    backgroundColor: "#f1f1fc",
    borderRadius: "50%",
    color: "#232459",
    cursor: "pointer",
    fontWeight: 'bolder',
  };
  pdfHide!: boolean;
  showCreateToe = true;
  isLinear = false;
  @Input() toeId: any;
  @Input() opportunityId: any;
  @Input() vendorID: any;

  constructor(
    private toeService: ToeListService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    // this.route.parent?.params.subscribe((res: any) => {
    //   this.opportunityId = atob(res.id);
    //   this.vendorID = res.vendorID;
      this.getUploadedList();
      //this.getprojectId();
    //})
  }

  getprojectId() {
    this.route.parent?.params.subscribe(params => {
      this.toeId = atob(params.id);
      console.log('getparmasid', this.toeId);

    });
  }

  screenInfo(id: any) {
    this.router.navigate([`toe/${btoa(this.toeId)}/delivery-contract`]);
  }


  backtoContract() {
    this.router.navigate([`toe/${btoa(this.toeId)}/delivery-contract`]);
  }




  getUploadedList() {
    this.spinner.show();
    this.toeService.getUploadedList(this.opportunityId, this.vendorID).subscribe((data: any) => {
      console.log('data', data);
      this.toefileEngagement = data.files['terms-of-engagement'];
      console.log('toefileEngagement', this.toefileEngagement);
      if (this.toefileEngagement.length != 0) {
        this.toefileEngagement1 = [];
        const ext = this.toefileEngagement[0].file.substring(this.toefileEngagement[0].file.lastIndexOf('.') + 1)
        const newObj = { file: this.toefileEngagement[0].file, doc: ext, size: (this.toefileEngagement[0].size) / 1000000 };
        this.toefileEngagement1.push(newObj);
      }
      this.fileDeliveryPartner = data.files['delivery-partner-response'];
      if (this.fileDeliveryPartner.length != 0) {
        this.fileDeliveryPartner1 = [];
        const ext = this.fileDeliveryPartner[0].file.substring(this.fileDeliveryPartner[0].file.lastIndexOf('.') + 1)
        const newObj = { file: this.fileDeliveryPartner[0].file, doc: ext, size: (this.fileDeliveryPartner[0].size) / 1000000 };
        this.fileDeliveryPartner1.push(newObj);
        console.log('Delivery-----', this.fileDeliveryPartner1)
      }
      this.contractCreation = data.files['contract-creation'];
      if (this.contractCreation.length != 0) {
        this.contractCreation1 = [];
        const ext = this.contractCreation[0].file.substring(this.contractCreation[0].file.lastIndexOf('.') + 1)
        const newObj = { file: this.contractCreation[0].file, doc: ext, size: (this.contractCreation[0].size) / 1000000 };
        this.contractCreation1.push(newObj);

      }
      this.signedContract = data.files['signed-contract'];
      if (this.signedContract.length != 0) {
        this.signedContract1 = [];
        const ext = this.signedContract[0].file.substring(this.signedContract[0].file.lastIndexOf('.') + 1)
        const newObj = { file: this.signedContract[0].file, doc: ext, size: (this.signedContract[0].size) / 1000000 };
        this.signedContract1.push(newObj);

      }
      this.data = data;
      //   this.data.vendor_details.last_updated_on = !!this.data.vendor_details?.last_updated_on ? moment(this.data.vendor_details?.last_updated_on).format('DD-MM-yyyy  HH:mm') : '';
      this.spinner.hide();
    }, error => {

      this.spinner.hide();
      throw error;
    })
  }


  onFileToeSelected(e: any) {
    console.log('e', e);
    const file = e;
    const fd: any = new FormData();
    fd.append('file_name', file.target.files[0].name);
    fd.append('file', file.target.files[0]);
    fd.append('vendor_id', this.vendorID);
    if (file.target.files[0].size > 5000000) {
      this.toastrService.error('Please upload less than 5MB');
      return;
    }
    this.spinner.show();
    this.toeService.uploadToeSection(fd, this.opportunityId).subscribe((res: any) => {
      this.getUploadedList();
      this.spinner.hide();
      this.toastrService.success('File uploaded successfully');
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  downloadToeFile(e: any) {
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.spinner.show();
    this.toeService.downloadToeSection(payload, this.opportunityId).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', e.file);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    })
  }


  downloaduploadSignedFile(e: any) {
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.spinner.show();
    this.toeService.downloadUploadSignedSection(payload, this.opportunityId).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', e.file);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    })
  }

  downloadPatnerResponseFile(e: any) {
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.spinner.show();
    this.toeService.downloadPatnerResponseSection(payload, this.opportunityId).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', e.file);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    })
  }

  
  downloadContractCreationFile(e: any) {
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.spinner.show();
    this.toeService.downloadContractCreationSection(payload, this.opportunityId).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', e.file);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    })
  }

  deleteToeFile(e: any) {
    this.spinner.show();
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.toeService.deleteToeSection(payload, this.opportunityId).subscribe((res: any) => {
      this.toastrService.success('File deleted successfully');
      this.spinner.hide();
      this.getUploadedList();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  deletePatnerResponseFile(e: any) {
    this.spinner.show();
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.toeService.deletePatnerResponseSection(payload, this.opportunityId).subscribe((res: any) => {
      this.toastrService.success('File deleted successfully');
      this.spinner.hide();
      this.getUploadedList();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  deletecontractCreation(e: any) {
    this.spinner.show();
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.toeService.deletecontractSection(payload, this.opportunityId).subscribe((res: any) => {
      this.toastrService.success('File deleted successfully');
      this.spinner.hide();
      this.getUploadedList();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }


  deleteuploadSigned(e: any) {
    this.spinner.show();
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.toeService.deleteuploadsignedSection(payload, this.opportunityId).subscribe((res: any) => {
      this.toastrService.success('File deleted successfully');
      this.spinner.hide();
      this.getUploadedList();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  onFileDeliverySelected(e: any) {
    const file = e;
    const fd: any = new FormData();
    fd.append('file_name', file.target.files[0].name);
    fd.append('file', file.target.files[0]);
    fd.append('vendor_id', this.vendorID);
    if (file.target.files[0].size > 5000000) {
      this.toastrService.error('Please upload less than 5MB');
      return;
    }
    this.spinner.show();
    this.toeService.uploadDelivery(fd, this.opportunityId).subscribe((res: any) => {
      this.getUploadedList();
      this.spinner.hide();
      this.toastrService.success('File uploaded successfully');
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  downloadDeliveryFile(e: any) {
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.spinner.show();
    this.toeService.downloadDelivery(payload, this.opportunityId).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', e.file);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    })
  }

  deleteDeliveryFile(e: any) {
    this.spinner.show();
    console.log('e', e);
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.toeService.deleteDelivery(payload, this.opportunityId).subscribe((res: any) => {
      this.toastrService.success('File deleted successfully');
      this.spinner.hide();
      this.getUploadedList();

    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  onFileContractSelected(e: any) {
    const file = e;
    const fd: any = new FormData();
    fd.append('file_name', file.target.files[0].name);
    fd.append('file', file.target.files[0]);
    fd.append('vendor_id', this.vendorID);
    if (file.target.files[0].size > 5000000) {
      this.toastrService.error('Please upload less than 5MB');
      return;
    }
    this.spinner.show();
    this.toeService.uploadContractCreation(fd, this.opportunityId).subscribe((res: any) => {
      this.getUploadedList();
      this.spinner.hide();
      this.toastrService.success('File uploaded successfully');
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  downloadContractFile(e: any) {
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.spinner.show();
    this.toeService.downloadContractCreation(payload, this.opportunityId).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', e.file);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    })
  }

  deleteContractFile(e: any) {
    this.spinner.show();
    console.log('e', e);
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.toeService.deleteContractCreation(payload, this.opportunityId).subscribe((res: any) => {
      this.toastrService.success('File deleted successfully');
      this.spinner.hide();
      this.getUploadedList();

    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  onFileSignedContract(e: any) {
    const file = e;
    const fd: any = new FormData();
    fd.append('file_name', file.target.files[0].name);
    fd.append('file', file.target.files[0]);
    fd.append('vendor_id', this.vendorID);
    if (file.target.files[0].size > 5000000) {
      this.toastrService.error('Please upload less than 5MB');
      return;
    }
    this.spinner.show();
    this.toeService.uploadSignedContract(fd, this.opportunityId).subscribe((res: any) => {
      this.getUploadedList();
      this.spinner.hide();
      this.toastrService.success('File uploaded successfully');
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  downloadSignedContract(e: any) {
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.spinner.show();
    this.toeService.downloadSignedContract(payload, this.opportunityId).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', e.file);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    })
  }

  deleteSignedContract(e: any) {
    this.spinner.show();
    console.log('e', e);
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.toeService.deleteSignedContract(payload, this.opportunityId).subscribe((res: any) => {
      this.toastrService.success('File deleted successfully');
      this.spinner.hide();
      this.getUploadedList();

    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  showDocument() {
    this.pdfHide = true;
    this.showCreate = false;
    this.showQuill = true;
    this.showCreateToe = false;
    setTimeout(() => this.setUpQuill(), 100);
  }

  setUpQuill() {
    this.quillInstance = new Quill("#editor", {
      theme: "snow",
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['code-block'],
          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],                                         // remove formatting button
          ['link'],
        ]
      }
    });
  }

  async exportPdf() {
    const delta = this.quillInstance.getContents();
    const blob = await pdfExporter.generatePdf(delta);
    saveAs(blob as Blob, "pdf-export.pdf");
    this.showQuill = false;
    this.showCreate = true;
  }

  clickPrevious() {
    this.pdfHide = false;
    this.showCreate = true;
    this.showQuill = false;
    this.showCreateToe = true;
  }

  goToList() {
    this.router.navigate([`toe/${btoa(this.opportunityId)}/delivery-contract`]);
  }

  goToResponse() {
    this.router.navigate([`toeUpload/${btoa(this.opportunityId)}/${this.vendorID}/toe-response`]);

  }

  getName(name: any) {
    return name?.split(' ').slice(0, 3).map((n: any) => n[0]).join('');
  }
  onStepChange(evt:any,stepper:any){
    console.log(evt);
    this.showCreate=true;
    if(evt.selectedIndex==1 && this.toefileEngagement?.length>0){
      //stepper.steps.forEach((e:any)=>e.completed = true)
    }
    // setTimeout(() => {
    //   this.stepper.steps.forEach((step:any, idx:any) => {
    //       step.select = () => {
    //           // Your custom code here
    //           // if you want to change step do execute code below
    //           console.log(step,idx);
              
    //       };
    //   });
  //});
    
  }
}
