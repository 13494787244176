import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { of} from 'rxjs';
import {tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToeListService {

  constructor(private http: HttpClient,private transferState: TransferState) { }

  getVendorToe(id: any) {
    return this.http.get(`terms-of-engagement/get-vendor-by-toe/?opportunity_id=${id}`);
  }

  updateToeApproval(payload: any) {
    return this.http.post(`terms-of-engagement/update-vendor-toe-status/`, payload);;
  }
  createSowContract(id: any,payload: any) {
    return this.http.post(`terms-of-engagement/create-sow-contract/${id}/`, payload);;
  }
  getSowContract(id: any,vendor_id:any) {
    return this.http.get(`terms-of-engagement/get-sow-contract/${id}/${vendor_id}`);;
  }
  getStatusList() {
    const statusKey = makeStateKey('vendor-toe-status-list');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get(`terms-of-engagement/vendor-toe-status-list/`).pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }

  getVendorContract(id: any) {
    return this.http.get(`terms-of-engagement/get-vendor-contract-by-toe/?opportunity_id=${id}`);
  }

  updateContractStatus(payload: any) {
    return this.http.post(`terms-of-engagement/update-vendor-contract-toe-status/`, payload);;
  }

  getContractStatus() {
    const statusKey = makeStateKey('vendor-contract-toe-status-list');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get(`terms-of-engagement/vendor-contract-toe-status-list/`).pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }

  getUploadedList(id: any, vendorId: any) {
    return this.http.get(`terms-of-engagement/get-uploaded-files-list/?opportunity_id=${id}&vendor_id=${vendorId}`);
  }

  deleteToeSection(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/terms-of-engagement-file-delete/${id}/`, payload);
  }
  deletePatnerResponseSection(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/delivery-partner-response-file-delete/${id}/`, payload);
  }

  deletecontractSection(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/contract-creation-file-delete/${id}/`, payload);
  }

  deleteuploadsignedSection(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/signed-contract-file-delete/${id}/`, payload);
  }


  deleteDelivery(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/delivery-partner-response-file-delete/${id}/`, payload);
  }

  deleteContractCreation(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/contract-creation-file-delete/${id}/`, payload);
  }

  deleteSignedContract(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/signed-contract-file-delete/${id}/`, payload);
  }

  downloadToeSection(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/terms-of-engagement-file-download/${id}/`, payload, { responseType: 'blob' });
  }
  downloadPatnerResponseSection(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/delivery-partner-response-file-download/${id}/`, payload, { responseType: 'blob' });
  }
  downloadContractCreationSection(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/contract-creation-file-download/${id}/`, payload, { responseType: 'blob' });
  }
  downloadUploadSignedSection(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/signed-contract-file-download/${id}/`, payload, { responseType: 'blob' });
  }

  downloadDelivery(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/delivery-partner-response-file-download/${id}/`, payload, { responseType: 'blob' });
  }

  downloadContractCreation(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/contract-creation-file-download/${id}/`, payload, { responseType: 'blob' });
  }

  downloadSignedContract(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/signed-contract-file-download/${id}/`, payload, { responseType: 'blob' });
  }

  uploadToeSection(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/terms-of-engagement-file-upload/${id}/`, payload);
  }

  uploadDelivery(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/delivery-partner-response-file-upload/${id}/`, payload);
  }

  uploadContractCreation(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/contract-creation-file-upload/${id}/`, payload);
  }

  uploadSignedContract(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/signed-contract-file-upload/${id}/`, payload);
  }

  getDeliverystatus() {
    const statusKey = makeStateKey('vendor-response-toe-status-list');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get(`terms-of-engagement/vendor-response-toe-status-list/`).pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }

  getDeliverypartner(id: any, vendorId: any) {
    return this.http.get(`terms-of-engagement/get-delivery-partner-response/${id}/${vendorId}/`);
  }

  updateDeliverypartner(id: any, payload: any) {
    return this.http.post(`terms-of-engagement/create-update-delivery-partner-response/${id}/`, payload);
  }

  uploadProposaldelivery(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/toe-response-proposal-file-upload/${id}/`, payload);
  }

  downloadProposalfile(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/toe-response-proposal-file-download/${id}/`, payload, { responseType: 'blob' });
  }

  deleteProposalfile(payload: any, id: any) {
    return this.http.post(`terms-of-engagement/toe-response-proposal-file-delete/${id}/`, payload);
  }

  getProposalfile(id: any, vendorId: any) {
    return this.http.get(`terms-of-engagement/toe-response-proposal-file-list/${id}/?vendor_id=${vendorId}`);
  }

}
