import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToeUploadRoutingModule } from './toe-upload-routing.module';
import { IndexComponent } from './index/index.component';
import { ToeFileUploadComponent } from './toe-file-upload/toe-file-upload.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AvatarModule } from 'ngx-avatar';
import { FormGroupNameToePipe, ToeResponseComponent } from './toe-response/toe-response.component';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    IndexComponent,
    ToeFileUploadComponent,
    ToeResponseComponent,
    FormGroupNameToePipe
  ],
  imports: [
    CommonModule,
    ToeUploadRoutingModule,
    SharedModule,
    NgxPermissionsModule,
    MatButtonModule,
    MatIconModule,
    AvatarModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatTooltipModule
  ],
  exports:[ToeResponseComponent]
})
export class ToeUploadModule { }
