@Pipe({
  name: 'formGroupNameToe'
})
export class FormGroupNameToePipe {
  transform(item:any,arr:any) {
    let formGroupNameIndex = 1000000;
    try {
      arr?.controls.forEach((res: any, index: any) => {
        if (res.controls.id.value == item.id) {
          formGroupNameIndex = index;;
          throw BreakException;
        }
      });      
    } catch (e) {
      if (e != BreakException) {
        throw e
      }
    }
    return formGroupNameIndex;
  }
}

import { Component, Input, OnInit, Output,EventEmitter, Pipe } from '@angular/core';
import { ActivatedRoute, Router, } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ToeListService } from 'src/app/shared/services/toe-list.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
var BreakException = {};
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ProgramService } from 'src/app/shared/services/program.service';
@Component({
  selector: 'app-toe-response',
  templateUrl: './toe-response.component.html',
  styleUrls: ['./toe-response.component.scss']
})
export class ToeResponseComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  opportunityId: any;
  @Input() vendorID: any;
  @Input() vendorStatus:any;
  @Input() opportunityStatus:any;
  proposalList: any;
  proposalList1: any[] = [];
  quillInstance: any;
  showTable = false;
  showCard = true;
  hideCard = false;
  auditLists: any;
  customStyle = {
    backgroundColor: "#f1f1fc",
    borderRadius: "50%",
    color: "#232459",
    cursor: "pointer",
    fontWeight: 'bolder',
  };
  data: any;
  toeForm: any;
  items: any;
  statusList: any;
  toeResponse: any;
  toeId: any;
  @Output() onClose = new EventEmitter();
  disableFields:boolean = false;

  constructor(
    private toeService: ToeListService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private ps:ProgramService
  ) { }

  ngOnInit(): void {
    this.route.parent?.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id);
      if(res.vendorID)
        this.vendorID = res.vendorID;
      this.getVendorList();
      this.getDeliverystatus();
      this.getUploadesdList();
      this.getDeliveryPartner();
      this.createForm();
    })
  }
  getUploadesdList() {
    this.spinner.show();
    this.toeService.getProposalfile(this.opportunityId, this.vendorID).subscribe((data: any) => {
      console.log('DATA---', data)
      this.proposalList = data;
      if (this.proposalList.length != 0) {
        this.proposalList1 = [];
        const ext = this.proposalList[0].file.substring(this.proposalList[0].file.lastIndexOf('.') + 1)
        const newObj = { file: this.proposalList[0].file, doc: ext?.toLowerCase(), size: (this.proposalList[0].size) / 1000000 };
        this.proposalList1.push(newObj);
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  getDeliveryPartner() {
    this.spinner.show();
    this.toeService.getDeliverypartner(this.opportunityId, this.vendorID).subscribe((data: any) => {
      this.auditLists = data.audit_log;
      this.auditLists.forEach((aud: any) => {
        aud.last_modified_on = !!aud.last_modified_on ? moment(aud.last_modified_on).format('DD-MM-yyyy  HH:mm') : '';
      });
      this.data = data.response_data;
      this.items = this.toeForm.get('items') as FormArray;
      this.data.forEach((d: any) => {
        d.term_category.term_subcategory.forEach((ts: any) => {
          ts.term_measurement_criteria.forEach((mc: any) => {
            this.items.push(this.createItem(mc));
          });
        });
      });
      this.ps.getOppData(this.opportunityId).subscribe((res:any)=>{ 
        this.ps.setStatusValue(res?.status);      
        if(this.vendorStatus == 'proposal_rejected' || this.vendorStatus == 'proposal_sow_signed' || this.opportunityStatus?.slug == 'opportunity_cancelled'){
          this.disableFields = true;
          this.toeForm.get("items").controls.forEach((control:any,index:number) => {
            this.toeForm.get("items").controls[index].disable();
          });
        }   
      }); 
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  createForm() {
    this.toeForm = new FormGroup({
      items: new FormArray([])
    });
  }


  createItem(data: any): FormGroup {
    return this.formBuilder.group({
      name: [data?.name || ''],
      status: [data?.status.slug || '',Validators.required],
      response: [data?.response || ''],
      notes: [data?.notes || ''],
      id: [data?.id || ''],
    });
  }

  toeValueFormArray() {
    return this.toeForm.get('items') as FormArray;
  }

  getFormGroupName(item: any): number {
    let formGroupNameIndex = 1000000;
    try {
      this.toeValueFormArray().controls.forEach((res: any, index: any) => {
        if (res.controls.id.value == item.id) {
          formGroupNameIndex = index;;
          throw BreakException;
        }
      });
    } catch (e) {
      if (e != BreakException) {
        throw e
      }
    }
    return formGroupNameIndex;
  }


  onProposalFileUpload(e: any) {
    const file = e;
    const fd: any = new FormData();
    fd.append('file_name', file.target.files[0].name);
    fd.append('file', file.target.files[0]);
    fd.append('vendor_id', this.vendorID);
    if (file.target.files[0].size > 5000000) {
      this.toastrService.error('Please upload less than 5MB');
      return;
    }
    this.spinner.show();
    this.toeService.uploadProposaldelivery(fd, this.opportunityId).subscribe((res: any) => {
      this.getUploadesdList();
      this.spinner.hide();
      this.toastrService.success('File uploaded successfully');
    }, error => {
      this.spinner.hide();
      throw error;
    })

  }

  downloadProposalFile(e: any) {
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.spinner.show();
    this.toeService.downloadProposalfile(payload, this.opportunityId).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', e.file);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    })
  }

  deleteProposalFile(e: any) {
    this.spinner.show();
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.toeService.deleteProposalfile(payload, this.opportunityId).subscribe((res: any) => {
      this.toastrService.success('File deleted successfully');
      this.spinner.hide();
      this.getUploadesdList();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }


  getDeliverystatus() {
    this.toeService.getDeliverystatus().subscribe((res: any) => {
      this.statusList = res;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  statusChange(e: any,option:any) {
    option['status']= option['status']?option['status']:{};
    option['status']['label'] = e.target.value;
  }

  saveResponse() {
    if(this.toeForm.invalid){
      this.toastrService.error('Please fill all the mandatory fields');
      return;
    }
    this.spinner.show();
    let toeForm = this.toeForm.controls.items.value;

    const payload: any = {
      vendor: this.vendorID,
      term_category: []
    };

    (this.data || []).forEach((d: any) => {
      (d.term_category.term_subcategory || []).forEach((ts: any) => {
        let term_category: any = {
          uuid: d.term_category.uuid,
          term_subcategory: []
        };

        let term_subcategory: any = {
          uuid: ts.uuid,
          term_measurement_criteria: []
        };

        (toeForm || []).forEach((tf: any) => {
          ts.term_measurement_criteria.forEach((mc: any) => {
            if (mc.id === tf.id) {
              const measure: any = {
                uuid: mc.uuid,
                status: tf.status,
                response: tf.response,
                notes: tf.notes
              };
              term_category.term_subcategory.push(term_subcategory);
              term_subcategory.term_measurement_criteria.push(measure);
            }
          });

        });
        payload.term_category.push(term_category);
      })
    })
    this.toeService.updateDeliverypartner(this.opportunityId, payload).subscribe((res: any) => {
      this.toastrService.success('Saved Successfully'); 
      // this.ps.getOpportunityById(this.opportunityId).subscribe((response: any) => {
      //   this.ps.setStatusValue(response?.status);
      // });
      //this.router.navigate(['deal-structure']);
      //this.router.navigate([`toe/${btoa(this.opportunityId)}/delivery-contract`]);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.error(error?.error);      
      throw error;
    })
  }

  editToe() {
    this.onClose.emit('');
    //this.router.navigate([`toeUpload/${btoa(this.opportunityId)}/${this.vendorID}/toe-files`]);
  }

  patnerContracts() {
    this.router.navigate([`toe/${btoa(this.opportunityId)}/delivery-contract`]);
  }

  clickPrevious() {
    this.router.navigate([`toeUpload/${btoa(this.opportunityId)}/${this.vendorID}/toe-files`]);
  }

  getVendorList() {
    this.spinner.show();
    this.toeService.getUploadedList(this.opportunityId, this.vendorID).subscribe((data: any) => {
      this.spinner.hide();
      this.toeResponse = data;
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  clickHide() {
    this.showTable = false;
    this.showCard = true;
    this.hideCard = !this.hideCard;
  }

  clickShow() {
    this.showTable = !this.showTable;
    this.hideCard = true;
    this.showCard = !this.showCard;

  }


  getShortName(fullName: any) {
    return fullName.split(' ').map((n: any) => n[0]).join('');
  }

  getName(name: any) {
    return name?.split(' ').slice(0, 2).map((n: any) => n[0]).join('');
  }



}
