<div class="d-flex m-0 toe-files py-2 px-3">
    <div class="col-md-12 p-0 section-1">
        <!-- <div class="d-flex">
            <app-bread-crumb></app-bread-crumb>
        </div> -->
        <div class="row">
            <!-- <div class="col-md-12">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex">

                        <div class="mt-2 px-2 cursor-pointer " (click)="clickPrevious()"><i
                                class="far fa-chevron-left fw-bold"></i></div>
                        <div class="mr-3 mt-2">
                            <span class="pt-3 name position-relative">
                                {{getName(toeResponse?.vendor_details?.name)}} </span>
                        </div>
                        <div class=" position: absolute  toe-response">

                            <div class="d-flex ">
                                <p class="toe-vendor-name">
                                    {{toeResponse?.vendor_details?.name | titlecase}}</p>
                            </div>
                            <br>
                            <div class="d-flex">

                                <p class="  text-muted toe-vendor-team-name">
                                    {{toeResponse?.vendor_details?.team}}
                                </p>
                            </div>
                        </div>
                    </div>
                     <div class="d-flex mb-4">
                        <button type="button" class="btn btn-light patner-btn" (click)="patnerContracts()">{{staticText?.toe_response.partner_contracts}}</button>
                    </div>
                </div>
            </div> -->
            <!-- <div>
                <hr class="m-0 p-0">
            </div> -->
            <div class="custom-table">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th class="text-center">
                                {{staticText?.toe_response.proposal_doc}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <td>
                            <p class="doc-subhead mb-1">{{staticText?.toe_response.content1}}</p>
                            <div *ngIf="proposalList?.length==0">
                                <div class="row" *ngIf="!disableFields">
                                    <div class="col-md-4 ">
                                        <div class="p-2 mt-1 upload">
                                            <div class="w-100 cursor-pointer d-flex justify-content-center"
                                                (click)="fileInput.click()">
                                                <span class="mt-1 ms-2 my-auto secondary">{{staticText?.common.upload_doc}}</span>
                                            </div>
                                            <input hidden #fileInput type="file" id="file" (change)="onProposalFileUpload($event)">
                                        </div>
                                    </div>
                                    <p class="fw-light file-status text-dark fs-14 mb-0"> {{staticText?.common.doc_ext_maxsize}}</p>
                                </div>
                            </div>
                            <div class="row" *ngIf="proposalList?.length>0">
                                <div class="row" *ngFor="let i of proposalList1">
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex">
                                            <div class="mr-2 mt-2">
                                                <img *ngIf="i.doc == 'doc' || i.doc == 'docx'"
                                                    src="./assets/images/word.svg" height="40">
                                                <img *ngIf="i.doc == 'pdf'" src="./assets/images/pdf.svg"
                                                    height="40">
                                                <img *ngIf="i.doc == 'xls' || i.doc == 'xlsx'"
                                                    src="./assets/images/excel.svg" height="40">
                                                <img *ngIf="i.doc == 'csv'" src="./assets/images/csv.png"
                                                    height="40">
                                                <mat-icon
                                                    *ngIf="i.doc !== 'pdf' && i.doc !== 'doc' && i.doc !== 'docx' && i.doc !== 'xls' && i.doc !== 'xlsx' && i.doc !== 'csv'"
                                                    mat-list-icon class="material-icons my-auto fs-1 text-danger">
                                                    description</mat-icon>
                                            </div>
                                            <div class="m-2 ms-0">
                                                {{i.file}}
                                                <p class="text-muted doc-subhead mb-0">{{i.size}}MB</p>
                                            </div>
            
                                        </div>
                                        <div class="d-flex">
                                            <div>
                                                <img (click)="downloadProposalFile(i)" class="cursor-pointer px-3"
                                                    src="./assets/images/download-icon-new.svg">
                                            </div>
                                            <div *ngIf="!disableFields">
                                                <img (click)="deleteProposalFile(i)" class="cursor-pointer px-3"
                                                    src="./assets/images/delete-icon-new.svg">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tbody>
                </table>
            </div>    
        </div>
        <div class="row">
            <div class="scroll-list">
                <div class="col-md-12 p-0">
                    <div class="row m-0 my-2" *ngFor="let k of data">
                        <div class="custom-table">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            {{k.term_category.display_name}}
                                        </th>
                                    </tr>
                                </thead>  
                                <tbody>  
                                    <tr>
                                        <td>
                                            <div *ngFor="let s of k.term_category.term_subcategory; let k=index">
                                                <div [formGroup]="toeForm">
                                                    <div formArrayName="items">
                                                        <div class="field-adjust">
                                                            <div class="row" *ngFor="let option of s.term_measurement_criteria">
                                                                <div class="col-md-12 p-0" [formGroupName]="option |formGroupNameToe :toeForm.get('items')">
                                                                    <div class="row px-2 mb-2">
                                                                        <div class="d-flex card-border">
                                                                            <div class="col-md-3 px-1 pt-1 d-flex align-items-center">
                                                                                <p class="pl-3 m-0"><i
                                                                                        class="fal fa-crosshairs aim px-2"></i>{{option.display_name}}
                                                                                </p>
                                                                            </div>
                                                                            <div class="col-md-3 px-1 col-status d-flex align-items-center justify-content-center">
                                                                                <mat-select class="w-75 pt-2 dropdown" [id]="'statusOpt'+option |formGroupNameToe :toeForm.get('items')"
                                                                                    (selectionChange)="option['slug']=$event?.value;" placeholder="SELECT"
                                                                                    formControlName="status"
                                                                                    [ngClass]="{'approved': option?.status?.label == 'Agree' || option['slug']=='toe_vendor_response_agree'  , 'rejected': option?.status?.label == 'Disagree'|| option['slug']=='toe_vendor_response_disagree' , 'partially-approved': option?.status?.label == 'Partially Agree'|| option['slug']=='toe_vendor_response_partially_agree' }">
                                                                                    <mat-option *ngFor='let i of statusList'
                                                                                        [value]="i.slug">
                                                                                        {{i.action_complete_label}}</mat-option>
                                                                                </mat-select>
                                                                            </div>
                                                                            <div class="col-md-3 px-1 col-patner d-flex align-items-center">
                                                                                <mat-form-field class="w-100" appearance="outline">
                                                                                    <mat-label>{{staticText?.toe_upload.dp_response}}</mat-label>
                                                                                    <textarea type="text" matInput status="basic" autosize
                                                                                        formControlName='response'>
                                                                            </textarea>
                                                                                </mat-form-field>

                                                                            </div>

                                                                            <div class="col-md-3 px-1 col-response d-flex align-items-center">
                                                                                <mat-form-field class=" w-100" appearance="outline">
                                                                                    <mat-label>{{staticText?.toe_response.notes}}</mat-label>
                                                                                    <textarea type="text" autosize
                                                                                        class="text-truncate  textarea-style" matInput
                                                                                        fullWidth status="basic" formControlName='notes'>
                                                                            </textarea>
                                                                                </mat-form-field>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex align-items-center justify-content-end mt-2 p-0 pt-2 footer-style" *ngIf="data?.term_category != ''">
                <button type="button" class="btn close-btn pr-2" (click)="editToe()" [ngClass]="{'mr-3':!disableFields}">Cancel</button>
                <button type="button" class="btn add-btn" *ngIf="!disableFields" (click)="saveResponse()">Save</button>
            </div>
        </div>
    </div>
</div>