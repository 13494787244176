<div class="d-flex m-0 toe-files">
    <div class="col-md-9 p-0 section-1">
        <div class="row">
            <div class="col-md-12">
                <app-bread-crumb></app-bread-crumb>
                <!-- <p class="text-muted" style="font-size: 12px;">Projects / FlyDelta /
                    FlyDelta Website Development / Terms of Engagement
                </p> -->
            </div>
            <div class="row">
                <div class="col-md-9">
                    <div class="header-section">
                        <h3 class="screen-name" *ngIf="showQuill" (click)="clickPrevious()">
                            <mat-icon aria-hidden="false" aria-label="material-icons-filled " class="cursor-pointer"
                                style="font-size: 35px;" >chevron_left
                            </mat-icon>
                            <h4 class="text-align fw-bold cursor-pointer">{{staticText?.toe.title}}</h4>
                        </h3>
                        <h3 class="screen-name" *ngIf="showCreateToe" (click)="goToList()">
                            <mat-icon aria-hidden="false" aria-label="material-icons-filled " class="cursor-pointer"
                                style="font-size: 35px;" >chevron_left
                            </mat-icon>
                            <h4 class="text-align fw-bold cursor-pointer">{{staticText?.toe.title}}</h4>
                        </h3>
                    </div>
                </div>

                <div class="col-md-3" (click)="goToResponse()">
                    <button mat-button class="float-end border primary lightSecondary add-patner-btn  ml-3"
                        mat-flat-button>
                        {{staticText?.toe_upload.partner_response}}
                        <mat-icon aria-hidden="false" aria-label="material-icons-filled " class="cursor-pointer fs-5">
                            chevron_right
                        </mat-icon>
                    </button>
                </div>
            </div>
            <div class="px-3">
                <hr class="mb-3 p-0">
            </div>
            <div id="setBox">
                <mat-stepper orientation="vertical" class="toe-upload-stepper" [linear]="isLinear" #stepper
                    (selectionChange)="onStepChange($event,stepper)">
                    <ng-template matStepperIcon="edit">
                        <mat-icon class="cursor-pointer">circle</mat-icon>
                    </ng-template>

                    <!-- <ng-template matStepperIcon="done" ><mat-icon class="cursor-pointer">done</mat-icon></ng-template> -->
                    <mat-step>
                        <div class="row center-section" *ngIf="showCreate">
                            <ng-template matStepLabel>{{staticText?.toe_upload.create_toe}}</ng-template>
                            <div class="col-md-12">

                                <div>
                                    <p class="mb-3">{{staticText?.toe_upload.content1}}</p>
                                </div>
                                <div *ngIf="toefileEngagement?.length==0">
                                    <div class="row" *ngIf="showCreate">
                                        <div class="col-md-4 ">
                                            <div class="p-2 mt-3 upload">
                                                <div class="w-100 cursor-pointer d-flex justify-content-center"
                                                    (click)="fileInput1.click()">
                                                    <span class="mt-1 ms-2 my-auto secondary">{{staticText?.common.upload_doc}}</span>
                                                </div>
                                                <input hidden #fileInput1 type="file" id="file"
                                                    (change)="onFileToeSelected($event)">
                                                <p class="fw-light">
                                                    {{staticText?.common.doc_ext_maxsize}}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="row " *ngIf="toefileEngagement?.length>0">
                                    <div class="row" *ngFor="let i of toefileEngagement1" [hidden]="pdfHide">
                                        <div class="col-md-5 upload-section mx-2 px-0">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div class="mb-3 mr-2">
                                                    <img *ngIf="i.doc == 'doc' || i.doc == 'docx'"
                                                        src="./assets/images/docs.png" height="40">
                                                    <img *ngIf="i.doc == 'pdf'"
                                                        src="./assets/images/pdf-icon-new.svg" height="40">
                                                    <mat-icon
                                                        *ngIf="i.doc !== 'pdf' && i.doc !== 'doc' && i.doc !== 'docx'"
                                                        mat-list-icon class="material-icons my-auto fs-1 text-danger">
                                                        description
                                                    </mat-icon>
                                                </div>
                                                <div class="m-2">
                                                    <span> {{i.file}}</span>
                                                    <p class="text-muted" style="font-size: 14px;">{{i.size}}MB</p>
                                                </div>
                                                <div class="p-2 mb-3">
                                                    <img (click)="downloadToeFile(i)" class="cursor-pointer"
                                                        src="./assets/images/download-icon-new.svg">
                                                </div>
                                                <div class="p-2 mb-3">
                                                    <img (click)="deleteToeFile(i)" class="cursor-pointer"
                                                        src="./assets/images/delete-icon-new.svg">
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="row" *ngIf="showQuill">
                                    <div class="col-md-12 col-12 ps-5 ms-3 quill-width">
                                        <div id="editor" class="vh-50 rounded"></div>
                                    </div>
                                    <div class="col-md-12">
                                        <div
                                            class="col-12 ps-5 mt-5 pt-3 text-end ms-3 pe-5 d-flex justify-content-end">
                                            <button mat-button id="pdf-btn" class="bg-purple text-white px-3 ms-4"
                                                (click)="exportPdf()">{{staticText?.toe_upload.save_doc_btn}}</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </mat-step>

                    <mat-step>
                        <div class="row center-section" *ngIf="showCreate">
                            <ng-template matStepLabel>{{staticText?.toe_upload.dp_response}}</ng-template>
                            <div class="col-md-12">

                                <div>
                                    <p class="mb-3">{{staticText?.toe_upload.upload_content}}</p>
                                </div>
                                <div *ngIf="fileDeliveryPartner?.length==0">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="p-2 mt-3 upload">
                                                <div class="w-100 cursor-pointer d-flex justify-content-center"
                                                    (click)="fileInput3.click()">
                                                    <span class="mt-1 ms-2 my-auto secondary"> {{staticText?.common.upload_doc}}</span>
                                                </div>
                                                <input hidden #fileInput3 type="file" id="file"
                                                    (change)="onFileDeliverySelected($event)">

                                                <p class="fw-light">
                                                    {{staticText?.common.doc_ext_maxsize}}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row" *ngIf="fileDeliveryPartner?.length>0">
                                    <div class="row" *ngFor="let i of fileDeliveryPartner1" [hidden]="pdfHide">
                                        <div class="col-md-6 upload-section">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div class="mb-3 mr-2">
                                                    <img *ngIf="i.doc == 'doc' || i.doc == 'docx'"
                                                        src="./assets/images/docs.png" height="40">
                                                    <img *ngIf="i.doc == 'pdf'"
                                                        src="./assets/images/pdf-icon-new.svg" height="40">
                                                    <mat-icon
                                                        *ngIf="i.doc !== 'pdf' && i.doc !== 'doc' && i.doc !== 'docx'"
                                                        mat-list-icon class="material-icons my-auto fs-1 text-danger">
                                                        description
                                                    </mat-icon>
                                                </div>
                                                <div class="m-2">
                                                    <span> {{i.file}}</span>
                                                    <p class="text-muted" style="font-size: 14px;">{{i.size}}MB</p>
                                                </div>
                                                <div class="p-2 mb-3">
                                                    <img (click)="downloadPatnerResponseFile(i)" class="cursor-pointer"
                                                        src="./assets/images/download-icon-new.svg">
                                                </div>
                                                <div class="p-2 mb-3">
                                                    <img (click)="deletePatnerResponseFile(i)" class="cursor-pointer"
                                                        src="./assets/images/delete-icon-new.svg">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step>
                        <div class="row center-section" *ngIf="showCreate">
                            <ng-template matStepLabel>{{staticText?.toe_upload.contract_creation}}</ng-template>
                            <div class="col-md-12">
                                <!--  <div class="pt-1">
                                <p class="font-weight-bold mb-2">Contract creation</p>
                            </div> -->
                                <div>
                                    <p class="mb-3">{{staticText?.toe_upload.content1}}</p>
                                </div>
                                <div *ngIf="contractCreation?.length==0">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="p-2 mt-3 upload">
                                                <div class="w-100 cursor-pointer d-flex justify-content-center"
                                                    (click)="fileInput5.click()">
                                                    <span class="mt-1 ms-2 my-auto secondary"> {{staticText?.common.upload_doc}}</span>
                                                </div>
                                                <input hidden #fileInput5 type="file" id="file"
                                                    (change)="onFileContractSelected($event)">

                                                <p class="fw-light">
                                                    {{staticText?.common.doc_ext_maxsize}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row" *ngIf="contractCreation?.length>0">
                                <div class="row" *ngFor="let i of contractCreation1" [hidden]="pdfHide">
                                    <div class="col-md-6 upload-section">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <div class="mb-3 mr-2">
                                                <img *ngIf="i.doc == 'doc' || i.doc == 'docx'"
                                                    src="./assets/images/docs.png" height="40">
                                                <img *ngIf="i.doc == 'pdf'"
                                                    src="./assets/images/pdf-icon-new.svg" height="40">
                                                <mat-icon *ngIf="i.doc !== 'pdf' && i.doc !== 'doc' && i.doc !== 'docx'"
                                                    mat-list-icon class="material-icons my-auto fs-1 text-danger">
                                                    description
                                                </mat-icon>
                                            </div>
                                            <div class="m-2">
                                                <span> {{i.file}}</span>
                                                <p class="text-muted" style="font-size: 14px;">{{i.size}}MB</p>
                                            </div>
                                            <div class="p-2 mb-3">
                                                <img (click)="downloadContractCreationFile(i)" class="cursor-pointer"
                                                    src="./assets/images/download-icon-new.svg">
                                            </div>
                                            <div class="p-2 mb-3">
                                                <img (click)="deletecontractCreation(i)" class="cursor-pointer"
                                                    src="./assets/images/delete-icon-new.svg">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-step>



                    <mat-step>
                        <ng-template matStepLabel>{{staticText?.toe_upload.upld_signed_contract}}</ng-template>
                        <div class="row center-section" *ngIf="showCreate">
                            <div class="col-md-12">
                                <!--  <div class="pt-1">
                                <p class="font-weight-bold mb-2">Upload Signed contract</p>
                            </div> -->
                                <div>
                                    <p class="mb-3">{{staticText?.toe_upload.content1}}</p>
                                </div>
                                <div *ngIf="signedContract?.length==0">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="p-2 mt-3 upload">
                                                <div class="w-100 cursor-pointer d-flex justify-content-center"
                                                    (click)="fileInput7.click()">
                                                    <span class="mt-1 ms-2 my-auto secondary"> {{staticText?.common.upload_doc}}</span>
                                                </div>
                                                <input hidden #fileInput7 type="file" id="file"
                                                    (change)="onFileSignedContract($event)">
                                                <p class="fw-light">
                                                    {{staticText?.common.doc_ext_maxsize}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="signedContract?.length>0">
                                    <div class="row" *ngFor="let i of signedContract1" [hidden]="pdfHide">
                                        <div class="col-md-6 upload-section">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div class="mb-3 mr-2">
                                                    <img *ngIf="i.doc == 'doc' || i.doc == 'docx'"
                                                        src="./assets/images/docs.png" height="40">
                                                    <img *ngIf="i.doc == 'pdf'"
                                                        src="./assets/images/pdf-icon-new.svg" height="40">
                                                    <mat-icon
                                                        *ngIf="i.doc !== 'pdf' && i.doc !== 'doc' && i.doc !== 'docx'"
                                                        mat-list-icon class="material-icons my-auto fs-1 text-danger">
                                                        description
                                                    </mat-icon>
                                                </div>
                                                <div class="m-2">
                                                    <span> {{i.file}}</span>
                                                    <p class="text-muted" style="font-size: 14px;">{{i.size}}MB</p>
                                                </div>
                                                <div class="p-2 mb-3">
                                                    <img (click)="downloaduploadSignedFile(i)" class="cursor-pointer"
                                                        src="./assets/images/download-icon-new.svg">
                                                </div>
                                                <div class="p-2 mb-3">
                                                    <img (click)="deleteuploadSigned(i)" class="cursor-pointer"
                                                        src="./assets/images/delete-icon-new.svg">
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </mat-step>
                </mat-stepper>
            </div>
        </div>
        <div class="row" *ngIf="showQuill">
            <div class="col-md-12 col-12 ps-5 ms-3 quill-width">
                <div id="editor" class="vh-50 rounded"></div>
            </div>
            <div class="col-md-12">
                <div class="col-12 ps-5 mt-5 pt-4 text-end d-flex justify-content-end">

                    <button mat-button id="pdf-btn" class="bg-purple text-white px-3 ms-4 btn-align"
                        (click)="exportPdf()">{{staticText?.toe_upload.save_doc_btn}}</button>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="showCreateToe">
            <div class="d-flex justify-content-end ">
                <button type="button" class="btn close-btn" (click)="backtoContract()">{{staticText?.buttons.close_btn}}</button>
            </div>
        </div>
    </div>
    <div class="col-md-auto p-0 section-2">
        <div class="d-flex">
            <div class="col-md-12 title">
                <h5>{{staticText?.buttons.contract_details}}</h5>
            </div>
        </div>

        <div class="p-3">
            <div class="d-flex">
                <div>
                    <label>
                        {{staticText?.buttons.portfolio}}
                    </label>

                    <div>
                        <p class="fw-bold mb-4">{{data?.portfolio_name}}</p>

                    </div>
                </div>

            </div>
            <div class="d-flex">
                <div>
                    <label>
                        {{staticText?.opportunity.title}}
                    </label>

                    <div>
                        <p class="fw-bold mb-4">{{data?.portfolio_name}}</p>

                    </div>
                </div>

            </div>
            <div class="d-flex">
                <div>
                    <label>
                        {{staticText?.toe_upload.dp}}
                    </label>

                    <div class="d-flex justify-content-between ">
                        <div class=" mr-2">
                            <span class="client-name mt-2 ">
                                {{getName(data?.vendor_details?.name)}} </span>
                        </div>
                        <div class="pt-2">
                            <p class="align-text mb-3 fw-bold " matTooltip={{data?.vendor_details?.name}}  matTooltipClass="example-tooltip">
                                {{data?.vendor_details?.name}}</p>
                            <p class="para-color para-align mb-0">{{data?.vendor_details?.team}}</p>
                        </div>
                    </div>
                </div>

            </div>

            <div class="d-flex">
                <div>
                    <label>
                        {{staticText?.toe_upload.primary_poc}}
                    </label>

                    <div class="d-flex justify-content-between">
                        <div class="mr-2">
                            <span class=" vendor-name mt-2">
                                {{getName(data?.vendor_details?.primary_contact_details?.full_name)}} </span>
                        </div>
                        <div class="pt-2">
                            <p class="align-text mb-3 fw-bold">
                                {{data?.vendor_details?.primary_contact_details?.full_name}}
                            </p>
                            <p class="para-color text-mail  para-align mb-0"
                                matTooltip={{data?.vendor_details?.primary_contact_details?.email}}  matTooltipClass="example-tooltip">
                                {{data?.vendor_details?.primary_contact_details?.email}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div>
                    <label>
                        {{staticText?.common.last_updated}}
                    </label>
                    <div>
                        <p class="fw-bold mb-4">{{data?.vendor_details?.last_updated_on | date}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>